@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.generated-consent-order>ol {
  @apply list-decimal ml-10;
}

.generated-consent-order>ol>li>ol {
  @apply ml-10;
  list-style: lower-alpha;
}

.generated-consent-order>ol>li>ol>li>ol {
  @apply ml-10;
  list-style: lower-roman;
}

.react-select__input>input:focus {
  box-shadow: none;
}

.signatures {
  @apply mt-8 ml-6 columns-2;
}

.signature {
  @apply pt-28 pr-5;
}

.signature>hr {
  @apply mb-2
}

.dates {
  @apply mt-8 ml-6 columns-2;
}
